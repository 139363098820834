import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  copy () {
    // const target = this.element.getAttribute("data-clipboard-target");
    const input = document.getElementById('api_key')

    // Create a temporary element to select and copy text
    const tempInput = document.createElement('input')
    tempInput.value = input.value
    document.body.appendChild(tempInput)
    tempInput.select()
    document.execCommand('copy')
    document.body.removeChild(tempInput)

    const tooltip = this.element.closest('.Polaris-TextField').querySelector('#tooltip')

    // Show the "Copied!" message
    tooltip.classList.remove('hidden')

    // Hide the message after 2 seconds
    setTimeout(() => {
      tooltip.classList.add('hidden')
    }, 2000)
  }
}
