import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'
import TomSelect from 'tom-select'

export default class extends Controller {
  static values = {
    placeholder: String,
    target: String,
    url: String,
    disabledAfterNew: Boolean,
    dropdownParent: String
  }

  connect () {
    const allowMultiple = this.element.multiple
    this.multiSelectField = new TomSelect(this.element, {
      closeAfterSelect: true,
      placeholder: this.placeholderValue || 'Search',
      plugins: allowMultiple ? ['remove_button'] : [],
      persist: true,
      dropdownParent: this.dropdownParentValue ? document.querySelector(this.dropdownParentValue) : null,
      render: this.urlValue
        ? {
            no_results: (data, escape) => {
              const button = document.createElement('button')
              button.setAttribute('type', 'button')
              button.textContent = 'Add New'
              button.onclick = () => {
                const params = new URLSearchParams()
                if (this.targetValue) {
                  params.append('target', this.targetValue)
                }

                get(`${this.urlValue}?${params}`, { responseKind: 'turbo-stream' })
                this.multiSelectField.close()
                this.disabledAfterNewValue && this.multiSelectField.disable()
              }

              return button
            }
          }
        : {}
    })
  }

  disconnect () {
    this.multiSelectField.destroy()
  }
}
