import { Controller } from '@hotwired/stimulus'

import { ClassicEditor, SourceEditing, FullPage, Essentials, Bold, Italic, Font, Paragraph, Table, TableToolbar, Image, ImageInsert } from 'ckeditor5'
import 'ckeditor5/ckeditor5.css'

export default class extends Controller {
  connect () {
    console.log('ckediotr connected')
    ClassicEditor
      .create(this.element, {
        plugins: [Essentials, SourceEditing, FullPage, Bold, Italic, Font, Paragraph, Table, TableToolbar, Image, ImageInsert],
        toolbar: {
          items: [
            'undo', 'redo', '|', 'bold', 'italic', '|',
            'fontSize', 'fontFamily', 'fontColor', 'SourceEditing', '|',
            'insertTable', 'tableRow', 'mergeTableCells', 'insertImage'
          ]
        },

        updateFormValue: 'onSubmit|onBlur|onUpdate',
        replace: true
      })
  }
}
